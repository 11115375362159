<template>
    <section class="section__content-wrapper">
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title">{{ $t('User.Upgrade your account') }}</span>
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <section class="section__container container container_active separator-when-scrolling pfull__16">
                <!--
                <h3 class="form-subtitle mb__12">Upgrade your account:</h3>
                -->
                <FieldGroup_input
                    :field-group_class="'mb__12'"
                    :id="'legal_name'"
                    :label="$t('User.Name company')"
                    :error="errors.legal_name"
                    v-model="user.legal_name"
                />
                <FieldGroup_input
                    :field-group_class="'mb__12'"
                    :id="'email_company'"
                    :label="$t('User.Support email')"
                    :error="errors.email"
                    v-model="user.email"
                />
                <FieldGroup_input
                    :field-group_class="'mb__12'"
                    :id="'phone'"
                    :label="$t('User.Phone')"
                    :error="errors.phone"
                    v-model="user.phone"
                />
                <FieldGroup_input
                    :field-group_class="'mb__12'"
                    :id="'contact-person'"
                    :label="$t('User.Contact person')"
                    :error="errors.contact_person"
                    v-model="user.contact_person"
                />

            </section>
        </section>

        <section class="section__footer">
            <button class="button button_view_base button_theme_primary mr__8" @click="onSendRequest"> {{ $t('buttons.Send-request') }} </button>
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{ $t('buttons.Cancel') }} </button>
        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {required, minLength, email} from "vuelidate/lib/validators";

    export default {
        name: "UserUpgrade",
        props: [],
        components: {},
        data() {
            return {
                user: {},
                errors: {},
            }
        },
        validations: {
            user: {
                legal_name: {required, minLength: minLength(3) },
                email: {required,email},
                phone: {required},
                contact_person: {required},
            }
        },
        computed: {
            ...mapGetters([
                "getAppUser",
            ]),
        },
        methods: {
            ...mapActions([
                "sendUserRequest",
                "setPopup"
            ]),
            onCancel(){
                this.$emit('close-dialog')
            },
            validateUser(){
                //let components = ['']
                this.$v.$touch()
                this.errors = {}
                // this.componentsErrors = [];
                let hasError;
                if (this.$v.$invalid) {
                    //if(components.includes(''))
                    {
                        hasError = false;
                        //legal_name
                        if(!this.$v.user.legal_name.required) {
                            hasError =
                            this.errors.legal_name= 'Name company is required'
                        }else
                        if(!this.$v.user.legal_name.minLength){
                            let min = this.$v.user.legal_name.$params.minLength.min
                            hasError =
                            this.errors.legal_name= 'Name company must be minimum '+min+' characters'
                        }
                        //email
                        if(!this.$v.user.email.required) {
                            hasError =
                            this.errors.email= 'Email company is required'
                        }else
                        if(!this.$v.user.email.email){
                            hasError =
                            this.errors.email= 'Email company must be email'
                        }
                        //phone
                        if(!this.$v.user.phone.required) {
                            hasError =
                            this.errors.phone= 'Phone is required'
                        }
                        //contact_person
                        if(!this.$v.user.contact_person.required) {
                            hasError =
                            this.errors.contact_person= 'Contact person is required'
                        }

                        if(hasError){
                            //this.componentsErrors.push('')
                        }
                    }
                }
            },
            onSendRequest(){
                this.validateUser()
                if(Object.keys(this.errors).length)
                    return false // stop here if form is invalid
                this.setPopup({
                    title: this.$t('User.Upgrade your account'),
                    methods: {
                        ok: () => this.onUpgrade(),
                        //cancel: () => {},
                    }
                })
            },
            onUpgrade(){
                this.sendUserRequest(this.user)
                    .then((/*res*/) => {
                        //console.log(res)
                        this.errors = {}
                        this.$toast.success(this.$t("success!"))
                        this.$emit('close-dialog')
                    })
                    .catch((error) => {
                        // Error
                        if (error.response) {
                            console.error(error.response)
                            if(error.response.status == 422){
                                this.errors = error.response.data.reduce((out, error) => {
                                    out[error.field] = error.message
                                    return out
                                }, {})
                            }
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        //console.error(error.config);
                    })
            },
        },
        created() {
            //console.log('UserUpgrade created', this.user.settings)
            let user = this.getAppUser
            this.user = {
                id: user.id,
                type: 'upgrade',
                legal_name: user.legal_name,
                email: user.email,
                phone: user.phone,
                contact_person: '',
            }
        },
        updated() {
            // console.log('UserUpgrade updated', this.user.settings)
        },
        mounted() {
            // console.log('UserUpgrade mounted', this.user.settings)
        }
    }
</script>

